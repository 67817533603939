exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-latest-news-tsx": () => import("./../../../src/pages/latest-news.tsx" /* webpackChunkName: "component---src-pages-latest-news-tsx" */),
  "component---src-pages-our-products-tsx": () => import("./../../../src/pages/our-products.tsx" /* webpackChunkName: "component---src-pages-our-products-tsx" */),
  "component---src-pages-products-bolts-tsx": () => import("./../../../src/pages/products/bolts.tsx" /* webpackChunkName: "component---src-pages-products-bolts-tsx" */),
  "component---src-pages-products-coils-tsx": () => import("./../../../src/pages/products/coils.tsx" /* webpackChunkName: "component---src-pages-products-coils-tsx" */),
  "component---src-pages-products-nuts-tsx": () => import("./../../../src/pages/products/nuts.tsx" /* webpackChunkName: "component---src-pages-products-nuts-tsx" */),
  "component---src-pages-products-other-products-tsx": () => import("./../../../src/pages/products/other-products.tsx" /* webpackChunkName: "component---src-pages-products-other-products-tsx" */),
  "component---src-pages-products-steel-plates-tsx": () => import("./../../../src/pages/products/steel-plates.tsx" /* webpackChunkName: "component---src-pages-products-steel-plates-tsx" */),
  "component---src-pages-products-threaded-rods-studs-tsx": () => import("./../../../src/pages/products/threaded-rods-studs.tsx" /* webpackChunkName: "component---src-pages-products-threaded-rods-studs-tsx" */),
  "component---src-pages-products-washers-tsx": () => import("./../../../src/pages/products/washers.tsx" /* webpackChunkName: "component---src-pages-products-washers-tsx" */)
}

